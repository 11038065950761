import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/Layout/Layout"
import Seo from "../../components/Seo"
import EntryHeader from "../../components/SingleTemplateComponents/EntryHeader"
import ShareBar from "../../components/SingleTemplateComponents/ShareBar"
import ComponentParser from "../../utils/ComponentParser"
import VideoEmbed from "../../components/SingleTemplateComponents/VideoEmbed"

const Discussion = ( { data: { discussion } } ) => {

	return (
		<Layout>
			<Seo
				title={discussion.title}
				canonical={discussion.seo?.canonical}
				uri={discussion.uri}
				socialImage={discussion.featuredImage?.node}
				description={discussion.seo?.metaDesc ? discussion.seo.metaDesc : discussion.excerpt.replace(/(<([^>]+)>)/gi, "") }
			/>

			{ discussion?.resourceMeta?.videoEmbed &&
				<div>
					<VideoEmbed 
						video={discussion.resourceMeta.videoEmbed}
					/>
				</div>
			}
            
			<EntryHeader
				title={discussion.title}
				date={discussion.date}
				icon={ discussion?.organizations?.nodes[0]?.organizationMeta?.logo?.localFile }
				format='Discussion'
			/>

			<ShareBar 
				url={discussion.uri}
			/>

			<div className="max-w-2xl mx-auto mt-4 md:-mt-64 pb-16 px-4 md:px-0 min-h-[40vh]">
				{discussion.blocks &&
				discussion.blocks.map((block, i) => {
					return (
						<div key={i}>
							<ComponentParser
								block={block}
								page={discussion}
							/>
						</div>
					)
				})}
				{ discussion.resourceMeta?.sourceUrl &&
						<p className="mt-12">
							This content was originally published on  
							
							<Link 
								to={discussion.resourceMeta.sourceUrl}
								target="_blank"
							>
								{discussion?.organizations?.nodes[0] ?
									' ' + discussion.organizations.nodes[0].name
								: 
									discussion.resourceMeta.sourceUrl
								}
							</Link>
						</p>
					}
			</div>
				
		</Layout>
	);
}

export const discussionQuery = graphql`
	query discussionById(
		$id: String!
	) {
		discussion: wpDiscussion( id: { eq: $id } ) {
			... DiscussionContent
		}
	}
`
export default Discussion